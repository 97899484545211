<template>
    <v-chip dark small :color="color" v-text="$t('lists.knowledgeType.' + value)"> </v-chip>
</template>
<script>
export default {
    name: 'knowledge-type',
    data: () => ({}),
    props: {
        value: {},
    },
    computed: {
        color() {
            if (this.value === 'tip') {
                return 'blue darken-3';
            } else if (this.value === 'question') {
                return 'amber darken-3';
            } else if (this.value === 'note') {
                return 'light-green darken-3';
            } else if (this.value === 'link') {
                return 'purple darken-3';
            } else if (this.value === 'video') {
                return 'pink darken-3';
            }
            return 'grey';
        },
    },
};
</script>
