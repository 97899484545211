var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"10","offset":"1"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"10","offset-md":"1"}},[_c('v-text-field',{attrs:{"placeholder":_vm.$t('knowledges.actions.search'),"solo":"","clearable":"","dense":"","rounded":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-card',[_c('v-card-text',[_c('v-toolbar',{staticClass:"mb-4",attrs:{"dense":"","flat":"","color":"transparent"}},[_c('v-spacer'),_c('v-btn',{staticClass:"d-print-none",attrs:{"color":"primary"},domProps:{"textContent":_vm._s(_vm.$t('buttons.createKnowledge'))},on:{"click":_vm.createKnowledge}})],1),_c('v-data-table',{staticClass:"selectable",attrs:{"calculate-widths":true,"headers":_vm.cols,"items":_vm.knowledges,"server-items-length":_vm.totalKnowledges,"footer-props":{
                        'items-per-page-options': [10, 20, 50, 100, 200],
                        'show-current-page': true,
                        'show-first-last-page': true,
                    },"options":_vm.options},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.openKnowledge},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
                    var item = ref.item;
return [_c('date-label',{attrs:{"value":item.createdAt}})]}},{key:"item.createdBy",fn:function(ref){
                    var item = ref.item;
return [_c('user-label',{attrs:{"value":item.createdBy,"avatar":true}})]}},{key:"item.type",fn:function(ref){
                    var item = ref.item;
return [_c('knowledge-type',{attrs:{"value":item.type}})]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }