<template>
    <v-row>
        <v-col cols="10" offset="1">
            <v-row>
                <v-col cols="12" md="10" offset-md="1">
                    <v-text-field
                        v-model="search"
                        :placeholder="$t('knowledges.actions.search')"
                        solo
                        clearable
                        dense
                        rounded
                        prepend-inner-icon="mdi-magnify"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-card>
                <v-card-text>
                    <v-toolbar dense flat class="mb-4" color="transparent">
                        <v-spacer></v-spacer>
                        <v-btn
                            class="d-print-none"
                            color="primary"
                            v-text="$t('buttons.createKnowledge')"
                            @click="createKnowledge"
                        />
                    </v-toolbar>
                    <v-data-table
                        :calculate-widths="true"
                        :headers="cols"
                        :items="knowledges"
                        :server-items-length="totalKnowledges"
                        :footer-props="{
                            'items-per-page-options': [10, 20, 50, 100, 200],
                            'show-current-page': true,
                            'show-first-last-page': true,
                        }"
                        :options.sync="options"
                        v-on:click:row="openKnowledge"
                        class="selectable"
                    >
                        <template v-slot:[`item.createdAt`]="{ item }">
                            <date-label :value="item.createdAt" />
                        </template>
                        <template v-slot:[`item.createdBy`]="{ item }">
                            <user-label :value="item.createdBy" :avatar="true" />
                        </template>
                        <template v-slot:[`item.type`]="{ item }">
                            <knowledge-type :value="item.type" />
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { formatDateMixin } from '@/mixins/dates';
import dateLabel from '@/components/labels/date';
import userLabel from '@/components/labels/user';
import knowledgeType from '@/components/knowledges/type';

export default {
    name: 'knowledges',
    data: () => ({
        search: undefined,
        options: {},
    }),
    mixins: [formatDateMixin],
    components: { dateLabel, userLabel, knowledgeType },
    created() {
        this.initContext();
    },
    watch: {
        options: function (value) {
            window.sessionStorage.setItem(
                this.sessionContextVariable,
                JSON.stringify(this.sessionContextVariableValue),
            );
            this.findKnowledges({
                options: value,
                term: this.search,
            });
        },
        search: function (query) {
            if (this.searchTimer) {
                clearTimeout(this.searchTimer);
                this.searchTimer = null;
            }

            this.searchTimer = setTimeout(() => {
                window.sessionStorage.setItem(
                    this.sessionContextVariable,
                    JSON.stringify(this.sessionContextVariableValue),
                );

                this.findKnowledges({
                    options: this.options,
                    term: query,
                });
            }, 800);
        },
    },
    computed: {
        ...mapGetters(['knowledges', 'totalKnowledges']),
        sessionContextVariable() {
            return `${this.$route.path}context`;
        },
        sessionContextVariableValue() {
            return {
                view: this.options,
                search: this.search,
            };
        },
        cols() {
            return [
                {
                    value: 'type',
                    text: this.$t('knowledges.attributes.type'),
                    sortable: true,
                    align: 'left',
                },
                {
                    value: 'name',
                    text: this.$t('knowledges.attributes.name'),
                    sortable: true,
                    align: 'left',
                },
                {
                    value: 'createdAt',
                    text: this.$t('knowledges.attributes.createdAt'),
                    sortable: true,
                    align: 'left',
                },
                {
                    value: 'createdBy',
                    text: this.$t('knowledges.attributes.createdBy'),
                    sortable: true,
                    align: 'left',
                },
            ];
        },
    },
    methods: {
        ...mapActions(['findKnowledges']),
        initContext() {
            const sessionValue = window.sessionStorage.getItem(this.sessionContextVariable);
            if (sessionValue) {
                const sessionJSON = JSON.parse(sessionValue);

                this.options = sessionJSON.view;
                this.search = sessionJSON.search;
            } else {
                this.options.page = 1;
                this.options.sortBy = ['createdAt'];
                this.options.sortDesc = ['asc'];
            }
        },
        createKnowledge() {
            this.$router.push({ name: 'knowledge-create' });
        },
        openKnowledge(item) {
            this.$router.push({ name: 'knowledge', params: { knowledge_id: item.id } });
        },
    },
};
</script>

<style scoped>
.selectable {
    cursor: pointer;
}
</style>
